//存储动态路由导航
//导入api接口
import { getMenu } from "../api/auth"
//倒入前端定义好的完整的路由信息
import {menuList} from "@/router/menuList";
//导入筛选路由的方法
import { menuRules } from "@/utils/menuRules";
//导入基本的路由配置layout的布局容器
import router,  { baseRouters } from "@/router/index";

import { cloneDeep } from "lodash"

export default{
    namespaced:true,
    state:{        
        dyMenuList: []//动态路由
    },
    getters:{

    },
    mutations:{
        //设置动态路由
        setMenuList(state, payload){
            // console.log(payload,"payload")
            state.dyMenuList = payload
        },
        deleteMenuList(state){
            state.dyMenuList = []
        }
    }, 
    actions:{
        getMenuList({commit, state, rootState}){
            return new Promise(async(resolve, reject) =>{
                // console.log(rootState.login.userinfo.role,"rootState")
                // getMenu({role:rootState.login.userinfo.role}).then(res=>console.log(res,"getMenu")).then(err=>)
                try{
                    const {data} = await getMenu({role:rootState.login.userinfo.role})
                    // console.log(data,"test123")
                    // console.log(router)

                    if(data.code === 200){
                        const result = data.data.menu

                        // console.log(result,  "permission----后端返回动态路由")
            
                        // console.log("------前端定义的完整路由-------", menuList)
            
                        //比较前后端的路由文件--筛选出符合后端接口的路由配置文件
                        let menus = menuRules(menuList, result)
            
                        // console.log("--------------- ",menus)
                        // console.log("baseRouters-----------", baseRouters)
            
                        let theRoutes = cloneDeep(baseRouters)
                        //合并路由导航
                        let routes = theRoutes[0].children//获取layout 布局里面的home

                        // console.log(routes,baseRouters,"routes?")
                        //添加动态获取匹配好的路由
                        routes.push(...menus)
                        // console.log("baseRouters-----------", theRoutes)
                        // console.log("合并的routes-----------", routes)
            
                        //存储动态路由 --vuex仓库
                        commit("setMenuList", routes)
            
                        //路由合并
                        // router.addRoutes(baseRouters)
                        // console.log(router)
            
                        // router.options.routes.push(baseRouters)
                        resolve(theRoutes)
                    }
                }catch(err){
                    console.error(err)
                    console.error(err.response)
                    if(err.response.status === 403){
                        router.push({name: "login"})
                    }
                }
            })
        }
    }
}