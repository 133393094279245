export default{
    namespaced:true,
    state:{
        userinfo:{
            token: "",
            user: "",
            uid: "",
            role: ""
        }
        
    },
    mutations:{
        setUser(state, payload){
            state.userinfo = payload
        },
        deleteUser(state){
             state.userinfo = {
                token: "",
                user: "",
                uid: "",
                role: ""
            }
        }
    }
}