import axios from "axios";
import store from "@/store";
const http = axios.create({
    baseURL: process.env.NODE_ENV==="production"? process.env.VUE_APP_BASE_URL:process.env.VUE_APP_BASE_URL,
    timeout: 10000
})
//添加请求拦截器
http.interceptors.request.use(function(config){
        //在发送请求之前做些什么
        // console.log(config)
        let token = store.state.login.userinfo.token
        // console.log(token,"token in interceptor")
        config.headers["Authorization"] = `Bearer ${token}`
        // console.log(config)

        return config;
    },function(error){
        //对请求错误做些什么
        return Promise.reject(error)
    });

//添加响应拦截器
http.interceptors.response.use(function(response){
        //对响应数据做点什么
        // console.log(response,"aa")
        return response;
    },function(error){
        // console.log(error,"xx")
        if(error.response.status === 403){
            window.localStorage.clear()
            window.location.replace("/#/login")
        }
        //对响应错误做点什么
        return Promise.reject(error)
    });

export default http