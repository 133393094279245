import http from "@/utils/request";

export const getUserTableData = (data) =>{
    return http.get(`/api/users/getUsers`, {params: data})
}

export const getEmployees = () =>{
    return http.get(`/api/users/employees`)
}

export const getRoles = () =>{
    return http.get(`/api/users/roles`)
}

export const addNewUser = (data) =>{
    return http.post(`/api/users`,data)
}

export const updateUser = (id, data) =>{
    return http.put(`/api/users/${id}`,data)
}

export const getUserById = (id) =>{
    return http.get(`/api/users/${id}`)
}

export const deleteUserById = (id) =>{
    return http.delete(`/api/users/${id}`)
}

export const getAllCustomers = () =>{
    return http.get(`/api/users/getAllCustomers`)
}
