import Cookie from "js-cookie"

export default{
    state:{
        isCollapse: false,
        tabList:[
            {
                path: "/",
                name: "home",
                label: "首页",
                icon: "s-home",
                url: "Home/Home"
            }
        ],//面包屑数据
        menu:[]
    },
    mutations:{
        //修改菜单展开收起的方法
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        //更新面包屑数据
        selectMenu(state, value){
            console.log(value,"?")
            //判断添加数据是否为首页 
            if(value.name !== "home"){
                const index = state.tabList.findIndex(res => res.name === value.name)
                //如果不存在
                if(index  ===  -1){
                    state.tabList.push(value)
                }
            }
        },
        //删除指定的tag
        closeTag(state, item){
            console.log("close",item)
            const index = state.tabList.findIndex(res => res.name === item.name)
            state.tabList.splice(index, 1)
        },
        setMenu(state, value){
            state.menu = value
            Cookie.set("menu", JSON.stringify(value))
        },
        //动态注册路由
        addMenu(state, router){
            ///判断缓存中是否有数据
            if(!Cookie.get("menu")) return
            console.log(">>>>>>>")
            const menu = JSON.parse(Cookie.get("menu"))
            state.menu = menu
            //组装动态路由的数据
            const menuArray = []
            menu.forEach(item =>{
                if(item.children){
                    item.children.map(item => {
                        item.component = () => import(`../views/${item.path}`)
                        return item
                    })
                    menuArray.push(...item.children)
                }else{
                    item.component  = () => import(`../views/${item.url}`)
                    menuArray.push(item)
                }
            })
            console.log(menuArray,"menuArray")
            menuArray.forEach(item =>{
                router.addRoute("main",item)
            })
        }
    }
}