import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router"
import store from "./store"
import "./api/mock"
import"./router/router-permission"
import 'bootstrap/dist/css/bootstrap.css'
import locale from 'element-ui/lib/locale/lang/en'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueReCaptcha } from "vue-recaptcha-v3"

// import _ from "lodash"

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon)

// console.log("process.env环境===========",process.env)
Vue.config.productionTip = false

//按需引入
// Vue.use(Row)
// Vue.use(Button)
Vue.use(ElementUI, { locale })

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY, loaderOptions: {
  autoHideBadge: true
}})

new Vue({
  router,
  store,
  render: h => h(App),
  // created(){
  //   store.commit("addMenu", router)
  // }
}).$mount('#app')
