import Mock from "mockjs"

let List = []

export default{
    createUser: config =>{
        const {name, addr,  age, birth,gender} = JSON.parse(config.body)

        List.unshift({
            id: Mock.Random.guid(),
            name:name,
            addr:addr,
            age:age,
            birth:birth,
            gender:gender
        })

        return{
            code: 20000,
            data:{
                message: "添加成功"
            }
        }
    }
    // getUserList: config=>{
    //     const  {name, page = 1, limit=  20 } = param2Obj()
    // }
}