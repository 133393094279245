<template>
    <div class="container">
        <div class="title"> 
            <div>
                {{ruleForm.serial}} 
                <el-tooltip class="item" v-if="ruleForm.remaining_days" effect="light" :content="ruleForm.remaining_days < 0? `${Math.abs(ruleForm.remaining_days)} day(s) expired`: `${ruleForm.remaining_days} day(s) left`" placement="top" style="margin-top: 10px">
                   <template v-if="ruleForm.status === 1">
                        <font-awesome-icon v-if="ruleForm.remaining_days >30"  icon="fa-solid fa-battery-full" style="color: #5cb85c; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 30 && ruleForm.remaining_days >= 8" icon="fa fa-battery-three-quarters" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 7 && ruleForm.remaining_days >= 4" icon="fa-solid fa-battery-half" style="color: #f1c40f; font-size: 1.5rem;"/>
                        <font-awesome-icon v-else-if="ruleForm.remaining_days <= 3 " icon="fa-solid fa-battery-quarter" style="color: red; font-size: 1.5rem;"/>
                    </template>
                    <template v-else-if="ruleForm.status === 2">
                        <font-awesome-icon icon="fa-solid fa-battery-empty" style="color: purple; font-size: 1.5rem;"/>
                    </template>
                </el-tooltip>
            </div>
            <el-button style="float:right" type="primary" icon="el-icon-download" :loading="isLoading" @click="pdfRegenerateHanlder" plain>
                {{ isLoading? "Loading..." : "View Certificate"}}
            </el-button>
            <!-- <el-dropdown style="float:right">
                <el-button type="primary" plain>
                    Certificate<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-download" @click.native="viewCertificate">View Certificate</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-download" @click.native="pdfRegenerateHanlder">View Certificate</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
        </div>
        <CommonDescription :items="generalDetails" title="General Details" :column="3"/>
        <CommonDescription :items="assemblyDetails" title="Assembly Details" :column="6"/>
        <CommonDescription :items="testDetails" title="Test Details" :column="6"/>

    </div>


</template>

<script>
import { getItemById } from "../../../api/items";
import { pdfGenerate } from "../../../api/pdf"
import Item from "@/mixins/item";
import moment from "moment";
import CommonDescription from  "../../../components/describtion"

export default {
    mixins: [Item],
    components:{
        CommonDescription
    },
    data() {
        return {
            ruleForm: {
                // title: "",
                serial: "",
                test_schedule: "",
                time_retest: "",
                order_number: "",
                ref_number: "",
                authorized_by: "",
                description: "",
                hosetype:"",
                code:"",
                static_length: "",
                nominal_bore: "",
                end_a: "",
                end_a_retention:"",
                end_b:"",
                end_b_retention:"",
                location:"",
                time_manufactured:"",
                remaining_days: 0,
                // time_expired: "",
                test_pressure:"",
                test_pressure_unit:1,
                test_pressure_result:0,
                test_length:"",
                test_length_result:0,
                final_length:"",
                final_length_result:0,
                continuity:"",
                continuity_result:0,
                resistance:"",
                resistance_result:0,
                authorized_by: "",
                user_id: "",
                certificate:"",
                customer: "",
                authorized_people:""
            },
            isTimeManufacturedDisplay: true,
            isLoading: false
        };
    },
    mounted(){
        getItemById(this.$route.params.id).then(res =>{
            // console.log(res,"getItemById")
            const {data, code} = res.data
            if(data.time_manufactured === "1900-01-01 00:00:00") this.isTimeManufacturedDisplay = false
            this.ruleForm = data
        }).catch(err => {
            console.warn(err.message)
            this.$message.error({message: err.message});
        })
    },
    computed:{
        statusRendering(){
            const {ruleForm} = this
            if(ruleForm.status === 1) return "Active"
            else if(ruleForm.status === 2) return "Archived"
            else return "Failed"
        },
        generalDetails(){
            const {ruleForm} = this
            return [
                {
                    title: "Order Number",
                    content: ruleForm.order_number,
                },
                {
                    title: "Ref Number",
                    content: ruleForm.ref_number
                },
                {
                    title: "Status",
                    content: this.statusRendering
                },
                {
                    title: "Test Schedule",
                    content: `${ruleForm.test_schedule} Months`
                },
                {
                    title: "Test Date",
                    content: ruleForm.test_date
                },
                {
                    title: "Retest Date",
                    content: ruleForm.time_retest
                },
                {
                    title: "Authorized By",
                    content: ruleForm.authorized_people,
                },
                {
                    title: "Customer",
                    content: ruleForm.customer,
                    span: 2
                },
                {
                    title: "Comments",
                    content: ruleForm.description
                },
            ]
        },
        assemblyDetails(){
            const {ruleForm} = this
            return [
                {
                    title: "Hose Code",
                    content: ruleForm.code,
                    span: 3
                },
                {
                    title: "Hose Type",
                    content: ruleForm.hosetype,
                    span: 3
                },
                {
                    title: "End A",
                    content: ruleForm.end_a,
                    span: 4
                },
                {
                    title: "End A Retention",
                    content: ruleForm.end_a_retention,
                    span: 1
                },
                {
                    title: "Static Length (mm)",
                    content: ruleForm.static_length,
                    span: 1
                },
                {
                    title: "End B",
                    content: ruleForm.end_b,
                    span: 4
                },
                {
                    title: "End B Retention",
                    content: ruleForm.end_b_retention,
                    span: 1
                },
                {
                    title: "Nominal Bore (mm)",
                    content: ruleForm.nominal_bore,
                    span: 1
                },
                {
                    title: "Assembly Location",
                    content: ruleForm.location,
                    span: 3
                },
                {
                    title: "Manufacture Date",
                    content: this.isTimeManufacturedDisplay? moment(ruleForm.time_manufactured).format('YYYY-MM-DD') : "Not Available",
                    span: 3
                }
            ]
        },
        testDetails(){
            const {ruleForm} = this
            return [
                {
                    title: "Test Pressure",
                    content: ruleForm.test_pressure_display === 1? ruleForm.test_pressure : "",
                    span: 3
                },
                {
                    title: "Result",
                    content: ruleForm.test_pressure_display === 1? (ruleForm.test_pressure_result? "Pass" : "Fail") : "",
                    span: 3
                },
                {
                    title: "Test Length (mm)",
                    content: ruleForm.test_elongation_display === 1? ruleForm.test_length : "",
                    span: 2
                },
                {
                    title: "Result",
                    content: ruleForm.test_elongation_display === 1? (ruleForm.test_length_result? "Pass" : "Fail") : "",
                },
                {
                    title: "Final Length (mm)",
                    content: ruleForm.test_elongation_display === 1? ruleForm.final_length : "",
                    span: 2
                },
                {
                    title: "Result",
                    content: ruleForm.test_elongation_display === 1? (ruleForm.final_length_result? "Pass" : "Fail") : "",
                },
                {
                    title: "Continuity",
                    content: ruleForm.test_continuity_display === 1? ruleForm.continuity : "",
                    span: 2
                },
                {
                    title: "Result",
                    content: ruleForm.test_continuity_display === 1? (ruleForm.continuity_result? "Pass" : "Fail") : "",
                },
                {
                    title: "Resistance (ohms)",
                    content: ruleForm.test_continuity_display === 1? ruleForm.resistance : "",
                    span: 2
                },
                {
                    title: "Result",
                    content: ruleForm.test_continuity_display === 1? (ruleForm.resistance_result? "Pass" : "Fail") : "",
                },
            ]
        },
    },
    methods:{
        pdfRegenerateHanlder(){
            this.isLoading = true
            // this.$confirm('Are you sure to regenerate the PDF?', '', {
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     type: 'warning'
            // }).then(() => {
            //     pdfGenerate({id: this.$route.params.id}).then(res =>{
            //         // console.log(res)
            //         this.ruleForm.certificate = res.data.data.certificate
            //         this.$message({ type: 'success', message: res.data.message });
            //     }).catch(err =>{
            //         console.error(err.message)
            //         this.$message.error({message: err.message});
            //     })
            // }).catch(() => {
            //     this.$message({ type: 'info', message: '已取消删除' });          
            // });
            
            pdfGenerate({id: this.$route.params.id}).then(res =>{
                const { code, data, message } = res.data
                if(code === 200) {
                    window.open(data.certificate, '_blank');
                    this.$message({ type: 'success', message });
                }
                this.isLoading = false
            }).catch(err =>{
                console.error(err.message)
                this.$message.error({message: err.message});
                this.isLoading = false
            })
        },
        viewCertificate(){
            window.open(this.ruleForm.certificate, '_blank');
        }
    }
}
</script>
<style lang="less" scoped>
.container{
    .title {
        // background-color: #eee;
        font-size: 1.8rem;
        color: #333;
        padding: 10px;
        font-weight: bold;
        display: flex;
        gap: 15px;
        justify-content: space-between;
    }
}
</style>