import Mock from "mockjs"

export default{
    getMenu: config =>{
        console.log(config,"?")
        const {username, password} = JSON.parse(config.body)

        if(username === "admin" && password === "admin"){
            return {
                code: 20000,
                data: {
                    menu:[
                        {
                            path: "/home",
                            name: "home",
                            label: "首页",
                            icon: "s-home",
                            url: "Home.vue"
                        },
                        // {
                        //     path: "/mall",
                        //     name: "mall",
                        //     label: "商品管理",
                        //     icon: "video-play",
                        //     url: "Mall.vue"
                        // },
                        // {
                        //     path: "/user",
                        //     name: "user",
                        //     label: "用户管理",
                        //     icon: "user",
                        //     url: "User.vue"
                        // },
                        // {
                        //     label: "其他",
                        //     icon: "location",
                        //     children:[
                        //         {
                        //             path: "page1",
                        //             name: "page1",
                        //             label: "页面1",
                        //             icon: "setting",
                        //             url: "PageOne.vue"
                        //         },{
                        //             path: "page2",
                        //             name: "page2",
                        //             label: "页面2",
                        //             icon: "setting",
                        //             url: "PageTwo.vue"
                        //         },
                        //     ]
                        // },
                        {
                            label: "Items",
                            icon: "location",
                            children:[
                                {
                                    path: "items",
                                    name: "items",
                                    label: "Items List",
                                    icon: "setting",
                                    url: "item/index.vue"
                                },
                            ]
                        },
                        {
                            label: "Users",
                            icon: "location",
                            children:[
                                {
                                    path: "users",
                                    name: "users",
                                    label: "Users List",
                                    icon: "setting",
                                    url: "users/index.vue"
                                },
                                // {
                                //     path: "page2",
                                //     name: "page2",
                                //     label: "页面2",
                                //     icon: "setting",
                                //     url: "PageTwo.vue"
                                // },
                            ]
                        },
                    ],
                    token: Mock.Random.guid(),
                    message: "获取成功"
                }
            }
        }else if(username === "kk" && password === "kk"){
            return{
                code: 20000,
                data: {
                    menu:[
                        {
                            path: "/home",
                            name: "home",
                            label: "首页",
                            icon: "s-home",
                            url: "Home.vue"
                        },
                        {
                            path: "/mall",
                            name: "mall",
                            label: "商品管理",
                            icon: "video-play",
                            url: "Mall.vue"
                        },
                        {
                            path: "/user",
                            name: "user",
                            label: "用户管理",
                            icon: "user",
                            url: "User.vue"
                        },
                    ],
                    token: Mock.Random.guid(),
                    message: "获取成功"
            }
        }
        }else{
            return{
                code:999,
                data:{
                    message: "密码错误"
                }
            }
        }
    }
}