import Item from "../views/item";
import ItemList from "../views/item/list";
import AddItem from "../views/item/add";
import EditItem from "../views/item/edit"
import ViewItem from "../views/item/view";

import Users from "../views/users";
import UsersList from "../views/users/list";
import AddUser from "../views/users/add";
import ViewUser from "../views/users/view";

import Companies from "../views/companies";
import CompaniesList from "../views/companies/list";
import AddCompany from "../views/companies/add";
import ViewCompany from "../views/companies/view";
import Employee from "../views/employee/list"

export const menuList = [
    {
        path: "/items", 
        name:"items", 
        component: Item,
        meta:{
            icon: "el-icon-s-shop"
        },
        children:[
            {
                path: "list", 
                name:"ItemsList", 
                component: ItemList, 
                meta:{
                    // activeMenu:"items",
                    // isLogin: true
                }
            },
            {
                path: "add", 
                name:"addItems", 
                component: AddItem, 
                meta:{
                    activeMenu:"ItemsList",
                    hide: true
                }
            },
            {
                path: "edit/:id", 
                name:"EditItem", 
                component: EditItem, 
                meta:{
                    activeMenu:"ItemsList",
                    hide:true
                }
            },
            {
                path: ":id", 
                name:"viewItems", 
                component: ViewItem, 
                meta:{
                    activeMenu:"ItemsList",
                    hide:true
                }
            },
        ]
    },
    {
        path: "/users", 
        name:"users", 
        component: Users,
        meta:{
            icon: "el-icon-user-solid"
        },
        children:[
            {
                path: "list", 
                name:"UsersList", 
                component: UsersList, 
                meta:{
                    // activeMenu:"users"
                }
            },
            {
                path: "add", 
                name:"addUser", 
                component: AddUser, 
                meta:{
                    activeMenu:"UsersList",
                    hide:true
                }
            },
            {
                path: ":id", 
                name:"viewUser", 
                component: ViewUser, 
                meta:{
                    activeMenu:"UsersList",
                    hide:true
                }
            },
        ]
    },
    {
        path: "/companies", 
        name:"companies", 
        component: Companies,
        meta:{
            icon: "el-icon-office-building"
        },
        children:[
            {
                path: "list", 
                name:"companiesList", 
                component: CompaniesList, 
                meta:{
                    // activeMenu:"companies"
                }
            },
            {
                path: "add", 
                name:"addCompany", 
                component: AddCompany, 
                meta:{
                    activeMenu:"companiesList",
                    hide:true
                }
            },
            {
                path: ":id", 
                name:"viewCompany", 
                component: ViewCompany, 
                meta:{
                    activeMenu:"companiesList",
                    hide:true
                }
            },
        ]
    },
    {path: "/employee", name:"Employee", component: Employee, meta:{ icon: "el-icon-s-marketing"}}
]