import http from "@/utils/request";

export const getItemTableData = (data) =>{
    // console.log(`/items/getItems?page_num=${data.currentPage}&page_size=${data.perPage}`)
    return http.get(`/api/items/getItems?page_num=${data.currentPage}&page_size=${data.perPage}`)
}

export const getMenu = (data) =>{
    console.log(data)
    return http.post('/api/permission/getMenu',data)
}

export const getItemById = (id) =>{
    return http.get(`/api/items/${id}`)
}

export const addNewItem = (data) =>{
    return http.post('/api/items',data)
}

export const deleteItemById = (id) =>{
    return http.delete(`/api/items/${id}`)
}

export const getItemsByUserId = (data) =>{
    // console.log(data)
    return http.get(`/api/items/getItemsByUserId?uid=${data.uid}&page_num=${data.currentPage}&page_size=${data.perPage}`)
}

export const updateItemsById = (data) =>{
    // console.log(data)
    return http.put(`/api/items`, data)
}

export const getItemSelections = () =>{
    return http.get(`/api/items/getItemSelections`)
}

export const getItemBySearch = (data) =>{
    return http.get(`/api/items/getItemBySearch`, {params: data})
}

export const getItemUnits = () =>{
    return http.get(`/api/items/units`)
}