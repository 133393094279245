<template>
    <el-form label-width="80px" :inline="true" ref="form" :model="form" :rules="rules" class="login-container">
        <h3 class="login_title">Wam Management System</h3>
        <el-form-item label="Email" prop="username">
            <el-input v-model="form.username" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
            <el-input type="password" v-model="form.password" placeholder=""></el-input>
        </el-form-item>
        <el-form-item style="text-align:right">
            <el-button @click="submit" type="primary">Login</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
// import Mock from "mockjs"
// import Cookie from "js-cookie"
// import {getMenu} from "../api"
import { login, recaptcha } from "../api/auth";
import { mapMutations } from "vuex";
export default{
    data(){
        return{
            form:{
                username: "",  
                password: ""
            },
            rules:{
                username:[
                    {required: true, trigger: "blur", message: "Please enter email"}
                ],
                password:[
                    {required: true, trigger: "blur", message: "Please enter password"}
                ],
            }
        }
    },
    methods:{
        ...mapMutations("login",["setUser"]),
        async submit(){
            //token
            // const token = Mock.Random.guid()
            // console.log(token)
            // //token 存入cookie 用于不同页面之间的通讯
            // Cookie.set("token", token)

            this.$refs.form.validate(async(valid) =>{
                if(valid){
                    // (optional) Wait until recaptcha has been loaded.
                    // await this.$recaptchaLoaded()

                    // Execute reCAPTCHA with action "login".
                    const token = await this.$recaptcha('login')
                    // console.log(token)

                    recaptcha({token}).then(res => {
                        // console.log(res)
                        const {code, message, result} = res.data
                        if(code === 200 && result.score > 0.5) {
                            login(this.form).then(({data} )=>{
                                // console.log(data)
                                if(data.code === 200){
                                    //token 存入cookie 用于不同页面之间的通讯
                                    // Cookie.set("token", data.data.token)

                                    let obj = {
                                        user: data.data.user,
                                        token: data.data.token,
                                        uid: data.data.id,
                                        role: data.data.role
                                    }

                                    this.setUser(obj)
                                    //获取菜单的数据,存入store中
                                    // this.$store.commit("setMenu", data.data.menu)

                                    // this.$store.commit("addMenu", this.$router)

                                    this.$router.push("/items/list").catch(_=>_)
                                }else{
                                    this.$message.error(data.message)
                                }
                            })
                        }else{
                            this.$message.error(message)
                        }
                    }).catch(err => {
                        this.$message.error(err.message)
                    })
                    
                }
            }) 

            //校验通过
            // this.$refs.form.validate((valid) =>{
            //     if(valid){
            //         getMenu(this.form).then(({data} )=>{
            //             console.log(data)
            //             if(data.code === 20000){
            //                 //token 存入cookie 用于不同页面之间的通讯
            //                 Cookie.set("token", data.data.code)

            //                 //获取菜单的数据,存入store中
            //                 // this.$store.commit("setMenu", data.data.menu)

            //                 // this.$store.commit("addMenu", this.$router)

            //                 this.$router.push("/home")
            //             }else{
            //                 this.$message.error(data.data.message)
            //             }
            //         })
            //     }
            // }) 

            //跳转到首页
            // this.$router.push("/home")
        }
    }
}
</script>
<style lang="less" scoped>
.login-container{
    width: 350px;
    border: 1px solid #eaeaea;
    margin: 180px auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
    // box-sizing: border-box;
    .login_title{
        text-align: center;
        margin-bottom: 40px;
        color: #505458;
        font-size: 20px;
    }
    .el-form-item{
        width: 100%;
    }
    .el-input{
        width: 198px;
    }
}
</style>