import http from "@/utils/request";

export const getAllCompanies = () =>{
    return http.get('/api/company/getAllCompanies')
}

export const getCompaniesTableData = (data) =>{
    return http.get(`/api/company/getCompanies`, {params: data})
}

export const addNewCompany = (data) =>{
    return http.post('/api/company', data)
}

export const getCompanyById = (id) =>{
    return http.get(`/api/company/${id}`)
}

export const deleteCompanyById = (id) =>{
    return http.delete(`/api/company/${id}`)
}

export const updateCompanyById = (id, data) =>{
    return http.put(`/api/company/${id}`,data)
}