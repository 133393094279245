<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-button type="primary" @click="addUser">Add New User</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-input placeholder="Search(Name, Email, Company)" v-model="searchData" @input="seachHandler" clearable
                    autofocus style="width: 300px;">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
        </el-row>
        <!-- <el-button type="primary" @click="addUser">Add New User</el-button> -->
        <el-table :data="tableData" stripe style="width: 100%" @row-dblclick="handleEdit">
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="company" label="Company"></el-table-column>
            <el-table-column prop="contact" label="Contact"></el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">edit</el-button> -->
                    <el-button size="mini" type="danger" plain @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <Pagination :total="total" :pageSize="pageSize" @getPagination="getPagination"/> -->
        <el-pagination @current-change="getPagination" :current-page="currentPage" :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { getUserTableData, deleteUserById } from "../../../api/user";
// import Pagination from "../../../components/pagination";
import { debounce } from "lodash"

export default {
    // components:{
    //     Pagination
    // },
    data() {
        return {
        tableData: [],
        total:0,
        pageSize: 50,
        currentPage: 1,
        searchData: ""
        }
    },
    created(){
        this.getTableList()
    },
    methods:{
        getPagination(page){
            // console.log("get page", page)
            this.getTableList(page)
        },
        getTableList(page = 1){
            this.currentPage = page 

            const {currentPage, pageSize, searchData} = this

            getUserTableData({page_num: currentPage, page_size: pageSize, searchText: searchData})
                .then(res => {
                    // console.log(res,"?")
                    if(res.data.code === 200){
                        this.tableData = res.data.data.result
                        this.total = res.data.data.page.total
                        this.pageSize = res.data.data.page.page_size
                    }
                })
                .catch(err => {
                    console.error(err.message)
                    // this.$message.error(err.message);
                })
        },
        addUser(){
            this.$router.push({name:"addUser"})
        },
        handleEdit(row, column, event) {
            // console.log(index, row);
            this.$router.push({name:"viewUser", params:{ id: row.id}})
            // if(this.userinfo.role === "customer"){
                // this.$router.push({name:"viewItems", params:{ id: row.id}})
            // }else{
            //     this.$router.push({name:"EditItem", params:{ id: row.id}})

            // }
        },
        handleDelete(index, row) {
            // console.log(index, row);

            this.$confirm('Are you sure to delete this user?', '', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                deleteUserById(row.id).then(res => {
                    const {message, code, data} = res.data
                    if(code === 200){
                        this.$message({message: message, type: 'success'});
                        this.tableData = this.tableData.filter(res => res.id !== data.id)
                    }else{
                        this.$message({message: message, type: 'warning'});
                    }
                }).catch(err=>{
                    this.$message.error({message: err.message});
                })
                
            }).catch(_ => _);
        },
        seachHandler: debounce(function (query) {
            this.searchData = query
            this.getTableList()
            // console.log(query)
        }, 1000),
    }
}
</script>