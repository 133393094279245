import Vue from "vue"
import Vuex from "vuex"
import tab from "./tab"
import login from "./login"
import menu from "./menu";
import createPersistedstate from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        tab,
        login,
        menu
    },
    plugins:[
        createPersistedstate({
            key:"data",
            path: [login]
        })
    ]
})