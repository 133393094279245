import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home"
import User from "../views/User"
import Main from "../views/Main"
import Mall from "../views/Mall"
import PageOne from "../views/PageOne"
import PageTwo from "../views/PageTwo"
import Login from "../views/Login"
import ItemPdf from "../views/pdf/ItemPdf";
import resetPassword from "../views/resetPassword";

// import Item from "../views/item";
// import ItemList from "../views/item/list";
// import AddItem from "../views/item/add";
// import ViewItem from "../views/item/view";

// import Users from "../views/users";
// import UsersList from "../views/users/list";
// import AddUser from "../views/users/add";
// import ViewUser from "../views/users/view";

// import Companies from "../views/companies";
// import CompaniesList from "../views/companies/list";
// import AddCompany from "../views/companies/add";
// import ViewCompany from "../views/companies/view";

import Cookie from "js-cookie"

Vue.use(VueRouter)

// const menuList = [
//     {
//         path: "/items", 
//         name:"items", 
//         component: Item,
//         children:[
//             {
//                 path: "list", 
//                 name:"ItemsList", 
//                 component: ItemList, 
//                 meta:{
//                     activeMenu:"/items",
//                     // isLogin: true
//                 }
//             },
//             {
//                 path: "add", 
//                 name:"addItems", 
//                 component: AddItem, 
//                 meta:{
//                     activeMenu:"/items"
//                 }
//             },
//             {
//                 path: ":id", 
//                 name:"viewItems", 
//                 component: ViewItem, 
//                 meta:{
//                     activeMenu:"/items"
//                 }
//             },
//         ]
//     },
//     {
//         path: "/users", 
//         name:"users", 
//         component: Users,
//         children:[
//             {
//                 path: "list", 
//                 name:"UsersList", 
//                 component: UsersList, 
//                 meta:{
//                     activeMenu:"/users"
//                 }
//             },
//             {
//                 path: "add", 
//                 name:"addUser", 
//                 component: AddUser, 
//                 meta:{
//                     activeMenu:"/users"
//                 }
//             },
//             {
//                 path: ":id", 
//                 name:"viewUser", 
//                 component: ViewUser, 
//                 meta:{
//                     activeMenu:"/users"
//                 }
//             },
//         ]
//     },
//     {
//         path: "/companies", 
//         name:"companies", 
//         component: Companies,
//         children:[
//             {
//                 path: "list", 
//                 name:"companiesList", 
//                 component: CompaniesList, 
//                 meta:{
//                     activeMenu:"/companies"
//                 }
//             },
//             {
//                 path: "add", 
//                 name:"addCompany", 
//                 component: AddCompany, 
//                 meta:{
//                     activeMenu:"/companies"
//                 }
//             },
//             {
//                 path: ":id", 
//                 name:"viewCompany", 
//                 component: ViewCompany, 
//                 meta:{
//                     activeMenu:"/companies"
//                 }
//             },
//         ]
//     },
// ]

export const baseRouters=[
    {
        path: "/", 
        component: Main,
        name: "main",
        redirect:"/items/list",
        meta:{
            isLogin: true
        },
        children:[
            {path: "/home", name:"home", component: Home, meta:{ icon: "el-icon-s-marketing"}},
            // {path: "/user", name:"user", component: User},
            // {path: "/mall", name:"mall", component: Mall},
            // {path: "/page1", name:"page1", component: PageOne},
            // {path: "/page2", name:"page2", component: PageTwo},
            // {
            //     path: "/items", 
            //     name:"items", 
            //     component: Item,
            //     children:[
            //         {
            //             path: "list", 
            //             name:"ItemsList", 
            //             component: ItemList, 
            //             meta:{
            //                 activeMenu:"/items",
            //                 // isLogin: true
            //             }
            //         },
            //         {
            //             path: "add", 
            //             name:"addItems", 
            //             component: AddItem, 
            //             meta:{
            //                 activeMenu:"/items"
            //             }
            //         },
            //         {
            //             path: ":id", 
            //             name:"viewItems", 
            //             component: ViewItem, 
            //             meta:{
            //                 activeMenu:"/items"
            //             }
            //         },
            //     ]
            // },
            // {
            //     path: "/users", 
            //     name:"users", 
            //     component: Users,
            //     children:[
            //         {
            //             path: "list", 
            //             name:"UsersList", 
            //             component: UsersList, 
            //             meta:{
            //                 activeMenu:"/users"
            //             }
            //         },
            //         {
            //             path: "add", 
            //             name:"addUser", 
            //             component: AddUser, 
            //             meta:{
            //                 activeMenu:"/users"
            //             }
            //         },
            //         {
            //             path: ":id", 
            //             name:"viewUser", 
            //             component: ViewUser, 
            //             meta:{
            //                 activeMenu:"/users"
            //             }
            //         },
            //     ]
            // },
            // {
            //     path: "/companies", 
            //     name:"companies", 
            //     component: Companies,
            //     children:[
            //         {
            //             path: "list", 
            //             name:"companiesList", 
            //             component: CompaniesList, 
            //             meta:{
            //                 activeMenu:"/companies"
            //             }
            //         },
            //         {
            //             path: "add", 
            //             name:"addCompany", 
            //             component: AddCompany, 
            //             meta:{
            //                 activeMenu:"/companies"
            //             }
            //         },
            //         {
            //             path: ":id", 
            //             name:"viewCompany", 
            //             component: ViewCompany, 
            //             meta:{
            //                 activeMenu:"/companies"
            //             }
            //         },
            //     ]
            // },
        ]
    },
    // {
    //     path: "/pdf", 
    //     name: "itemPdf",
    //     component: ItemPdf
    // },
    // {
    //     404
    // }
]

export const routes =[
    // {
    //     path: "/", 
    //     component: Main,
    //     name: "main",
    //     redirect:"/home",
    //     meta:{
    //         isLogin: true
    //     },
    //     children:[
    //         {path: "/home", name:"home", component: Home},
    //         {path: "/user", name:"user", component: User},
    //         {path: "/mall", name:"mall", component: Mall},
    //         {path: "/page1", name:"page1", component: PageOne},
    //         {path: "/page2", name:"page2", component: PageTwo},
    //         {
    //             path: "/items", 
    //             name:"items", 
    //             component: Item,
    //             children:[
    //                 {
    //                     path: "list", 
    //                     name:"ItemsList", 
    //                     component: ItemList, 
    //                     meta:{
    //                         activeMenu:"/items",
    //                         // isLogin: true
    //                     }
    //                 },
    //                 {
    //                     path: "add", 
    //                     name:"addItems", 
    //                     component: AddItem, 
    //                     meta:{
    //                         activeMenu:"/items"
    //                     }
    //                 },
    //                 {
    //                     path: ":id", 
    //                     name:"viewItems", 
    //                     component: ViewItem, 
    //                     meta:{
    //                         activeMenu:"/items"
    //                     }
    //                 },
    //             ]
    //         },
    //         {
    //             path: "/users", 
    //             name:"users", 
    //             component: Users,
    //             children:[
    //                 {
    //                     path: "list", 
    //                     name:"UsersList", 
    //                     component: UsersList, 
    //                     meta:{
    //                         activeMenu:"/users"
    //                     }
    //                 },
    //                 {
    //                     path: "add", 
    //                     name:"addUser", 
    //                     component: AddUser, 
    //                     meta:{
    //                         activeMenu:"/users"
    //                     }
    //                 },
    //                 {
    //                     path: ":id", 
    //                     name:"viewUser", 
    //                     component: ViewUser, 
    //                     meta:{
    //                         activeMenu:"/users"
    //                     }
    //                 },
    //             ]
    //         },
    //         {
    //             path: "/companies", 
    //             name:"companies", 
    //             component: Companies,
    //             children:[
    //                 {
    //                     path: "list", 
    //                     name:"companiesList", 
    //                     component: CompaniesList, 
    //                     meta:{
    //                         activeMenu:"/companies"
    //                     }
    //                 },
    //                 {
    //                     path: "add", 
    //                     name:"addCompany", 
    //                     component: AddCompany, 
    //                     meta:{
    //                         activeMenu:"/companies"
    //                     }
    //                 },
    //                 {
    //                     path: ":id", 
    //                     name:"viewCompany", 
    //                     component: ViewCompany, 
    //                     meta:{
    //                         activeMenu:"/companies"
    //                     }
    //                 },
    //             ]
    //         },
    //     ]
    // },
    {
        path: "/login", 
        name: "login",
        component: Login
    },
    {
        path: "/pdf/:id", 
        name: "itemPdf",
        component: ItemPdf
    },
    {
        path: "/reset-password/:id/:token", 
        name: "resetPassword",
        component: resetPassword
    },
    // {path: "/user", component: User}
]

const router = new VueRouter({
    routes
})
// router.beforeEach((to, from, next) =>{
//     console.log("to",to)
//     console.log("from",from)

//     // next()

//     //判断token是否存在
//     const token = Cookie.get("token")

//     //token不存在,说明当前用户是未登录，应该跳转到登录项
//     if(!token && to.name !== "login"){
//         next({name: "login"})
//     }else if(token && to.name === "login"){
//         //token存在， 说明用户登录，此时跳至首页
//         next({name: "home"})
//     }else{
//         next()
//     }
// })
export default router;
