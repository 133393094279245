import router, { baseRouters } from "./index"
import store from "@/store";

router.beforeEach((to, from, next) =>{
    // console.log("导航全局前置守卫 to————————————",to)
    // console.log(to.matched.length,to.matched.some(item => item.meta.isLogin))
    if(to.matched.length && !to.matched.some(item => item.meta.isLogin)){
        //不需要登录
        // console.log('不需要登录')
        next()
    }else{
        //需要登录
        //判断是否需要登录1.是否已经登录 2.根据token
        // console.log(store.state)
        // console.log("需要登录")
        let token = store.state.login.userinfo.token
        if(token){
            // console.log("有token")
            //判断时候又导航存储vuex里 有就进入 没有导航----获取动态导航
            if(store.state.menu.dyMenuList.length != 0){
                //有导航
                // console.log(store.state.menu.dyMenuList,"有导航")
                // console.log(router.getRoutes(),"to-----")
                if(router.getRoutes().length > 3){
                    // console.log("router.getRoutes().length > 1")
                    // console.log(router.getRoutes(),"to-----")
                    next()
                }else{
                    // console.log("router.getRoutes().length = 1")
                    store.dispatch("menu/getMenuList")
                    .then(baseRouters =>{
                        // console.log(baseRouters,"baseRouters")
                        baseRouters.forEach(element => {

                            router.addRoute(element)
                        });
                        // router.options.routes.push(baseRouters)
                        // router.addRoutes(baseRouters);
                        // console.log(router,"router----")
                        // console.log(to,"to-----")
                        // console.log(router.getRoutes(),"to-----")

                        //调用next
                        //next({...to, replace: true}) 中断当前导航 执行新的导航
                        next({...to, replace: true})
                    })
                }
                // next()
            }else{
                console.log(store.state.menu.dyMenuList,"没有导航")
                //没有导航----获取导航  
                store.dispatch("menu/getMenuList")
                    .then(baseRouters =>{
                        // console.log(baseRouters,"baseRouters")
                        baseRouters.forEach(element => {
                            console.log(element,"ekle")
                            router.addRoute(element)
                        });
                        // router.options.routes.push(baseRouters)
                        // router.addRoutes(baseRouters);
                        // console.log(router,"router----")
                        // console.log(to,"to-----")
                        // console.log(router.getRoutes(),"to-----")

                        //调用next
                        //next({...to, replace: true}) 中断当前导航 执行新的导航
                        next({...to, replace: true})
                    })
                // next()
            }
            // next()
        }else{
            // console.log("没有token")
        
            if(to.name === "login") next()
            next({name: "login"})
        }
    }
})

// router.beforeEach((to, from, next) =>{
//     console.log("导航全局前置守卫 to————————————",to,from)
//     console.log(to.matched.length,to.matched.some(item => item.meta.isLogin))
//     let token = store.state.login.userinfo.token
//     console.log(router.getRoutes())
//     if(token){
//         console.log("有token")
//         if(store.state.menu.dyMenuList.length != 0){
//             //有导航
//             console.log("有导航")
//             next()
//         }else{
//             console.log("没有导航")
//             //没有导航----获取导航  
//             store.dispatch("menu/getMenuList")
//                 .then(baseRouters =>{
//                     console.log(baseRouters,"baseRouters")
//                     baseRouters.forEach(element => {
//                         console.log(element,"ekle")
//                         router.addRoute(element)
//                     });
//                     // router.options.routes.push(baseRouters)
//                     // router.addRoutes(baseRouters);
//                     console.log(router,"router----")
//                     console.log(to,"to-----")
//                     console.log(router.getRoutes(),"to-----")

//                     //调用next
//                     //next({...to, replace: true}) 中断当前导航 执行新的导航
//                     next({...to, replace: true})
//                 })
//             // next()
//         }
//     }else{
//         console.log("没有token")
        
//         if(to.name === "login") next()
//         next({name: "login"})
//     }

//     // next()
//     //判断是否有token
//     // if(token){
//     //     console.log("hastoken",to.name)
//     //     // //有token但要去login，返回到home
//     //     // if(to.name === "login"){
//     //     //     next({name: "home"})
//     //     // }else{
//     //     //     //区别的地方
//     //     //     if(router.getRoutes().length > 1){
//     //     //         //有导航
//     //     //         console.log("?1")
//     //     //         console.log(router.getRoutes(),"to-----")
//     //     //         next()
//     //     //     }else{
//     //     //         store.dispatch("menu/getMenuList")
//     //     //             .then(baseRouters =>{
//     //     //                 console.log(baseRouters,"baseRouters")
//     //     //                 baseRouters.forEach(element => {
//     //     //                     console.log(element,"ekle")
//     //     //                     router.addRoute(element)
//     //     //                 });
//     //     //                 // router.options.routes.push(baseRouters)
//     //     //                 // router.addRoutes(baseRouters);
//     //     //                 console.log(router,"router----")
//     //     //                 console.log(to,"to-----")
//     //     //                 console.log(router.getRoutes(),"to-----")
//     //     //                     next({...to, replace: true})
                        
//     //     //             })
//     //     //     }
//     //     // }
//     //     next()
//     // }else{
//     //     console.log("notoken")

//     //     next({path: '/login'})
//     // }
// })

// router.beforeEach((to, from, next) =>{
//     console.log("导航全局前置守卫 to————————————",to)
//     console.log(to.matched.length,to.matched.some(item => item.meta.isLogin))
//     let token = store.state.login.userinfo.token
//     console.log("token", token)
//     if(to.matched.length && !to.matched.some(item => item.meta.isLogin && token)){
//         //不需要登录
//         console.log(router.getRoutes(),"1to-----")

//         if(to.name === "login"){
//             next({name: "home"})
//         }else{
//            next() 
//         }
//     }else{
//         //需要登录
//         //判断是否需要登录1.是否已经登录 2.根据token
//         // console.log(store.state)
//         console.log("token?", token)
//         if(token){

//             //判断时候又导航存储vuex里 有就进入 没有导航----获取动态导航
//             if(router.getRoutes().length > 1){
//                 //有导航
//                 console.log(router.getRoutes(),"2to-----")
//                 next()
                
//             }else{
//                 // console.log("?2")
//                 //没有导航----获取导航  
//                 store.dispatch("menu/getMenuList")
//                     .then(baseRouters =>{
//                         console.log(baseRouters,"baseRouters")
//                         baseRouters.forEach(element => {
//                             console.log(element,"ekle")
//                             router.addRoute(element)
//                         });
//                         // router.options.routes.push(baseRouters)
//                         // router.addRoutes(baseRouters);
//                         // console.log(router,"router----")
//                         // console.log(to,"to-----")
//                         // console.log(router.getRoutes(),"to-----")

//                         //调用next
//                         //next({...to, replace: true}) 中断当前导航 执行新的导航
//                         // if(token && to.name === "login"){
//                         //         //token存在， 说明用户登录，此时跳至首页
//                         //         next({name: "home"})
//                         // }else{
//                             next({...to, replace: true})
//                         // }
                        
//                     })
//                 // next()
//             }
//             console.log(2)
//             // next()
//         }else{
//             console.log(3)
//             console.log(router.getRoutes(),"3to-----")
//             next("/login")
//         }
//     }
// })

// router.beforeEach((to, from, next) =>{
//     console.log("导航全局前置守卫 to————————————",to,from)
//     console.log(to.matched.length,to.matched.some(item => item.meta.isLogin))
//     let token = store.state.login.userinfo.token
//     console.log(router.getRoutes())
//     console.log(token)

//     next()
//     //判断是否有token
//     // if(token){
//     //     console.log("hastoken",to.name)
//     //     // //有token但要去login，返回到home
//     //     // if(to.name === "login"){
//     //     //     next({name: "home"})
//     //     // }else{
//     //     //     //区别的地方
//     //     //     if(router.getRoutes().length > 1){
//     //     //         //有导航
//     //     //         console.log("?1")
//     //     //         console.log(router.getRoutes(),"to-----")
//     //     //         next()
//     //     //     }else{
//     //     //         store.dispatch("menu/getMenuList")
//     //     //             .then(baseRouters =>{
//     //     //                 console.log(baseRouters,"baseRouters")
//     //     //                 baseRouters.forEach(element => {
//     //     //                     console.log(element,"ekle")
//     //     //                     router.addRoute(element)
//     //     //                 });
//     //     //                 // router.options.routes.push(baseRouters)
//     //     //                 // router.addRoutes(baseRouters);
//     //     //                 console.log(router,"router----")
//     //     //                 console.log(to,"to-----")
//     //     //                 console.log(router.getRoutes(),"to-----")
//     //     //                     next({...to, replace: true})
                        
//     //     //             })
//     //     //     }
//     //     // }
//     //     next()
//     // }else{
//     //     console.log("notoken")

//     //     next({path: '/login'})
//     // }
// })