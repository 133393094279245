import Mock from "mockjs"

let List = []

export default{
    getStatisticalData:()=>{
        //Mock.Random.float 产生随机数100到8000之间 保留小数 最小0位 最大0位
        for(let i = 0; i < 7; i++){
            List.push(
                Mock.mock({
                    苹果: Mock.Random.float(100,8000,0,0),
                    vivo: Mock.Random.float(100,8000,0,0),
                    oppo: Mock.Random.float(100,8000,0,0),
                    魅族: Mock.Random.float(100,8000,0,0),
                    三星: Mock.Random.float(100,8000,0,0),
                    小米: Mock.Random.float(100,8000,0,0)
                })
            )
        }
        return {
            code: 20000,
            data:{
                //饼图
                videoData:[
                    {
                        name: "小米",
                        value: 2999
                    },
                    {
                        name: "苹果",
                        value: 6999
                    },
                    {
                        name: "vivo",
                        value: 1999
                    },
                    {
                        name: "魅族",
                        value: 999
                    },
                    {
                        name: "oppo",
                        value: 99
                    },
                    {
                        name: "三星",
                        value: 9
                    }
                ],
                //柱状图
                userData:[
                    {
                        date: "周一",
                        new: 5,
                        active:200
                    },
                    {
                        date: "周2",
                        new: 5,
                        active:200
                    },
                    {
                        date: "周3",
                        new: 5,
                        active:200
                    },
                    {
                        date: "周4",
                        new: 5,
                        active:200
                    },
                    {
                        date: "周5",
                        new: 5,
                        active:200
                    },
                    {
                        date: "周6",
                        new: 5,
                        active:200
                    },
                ],
                //折线图
                orderData:{
                    date:["20230101","20230102","20230103","20230104","20230105"],
                    data:List
                },
                tableData:[
                    {
                        name: "oppo",
                        todayBuy: 500,
                        mounthBuy:3500,
                        totalBuy:22000
                    },
                    {
                        name: "小米",
                        todayBuy: 300,
                        mounthBuy:2500,
                        totalBuy:12000
                    },
                    {
                        name: "苹果",
                        todayBuy: 1500,
                        mounthBuy:13500,
                        totalBuy:122000
                    },
                    {
                        name: "三星",
                        todayBuy: 100,
                        mounthBuy:1500,
                        totalBuy:12000
                    },
                    {
                        name: "魅族",
                        todayBuy: 500,
                        mounthBuy:3500,
                        totalBuy:22000
                    },
                    {
                        name: "vivo",
                        todayBuy: 500,
                        mounthBuy:3500,
                        totalBuy:22000
                    },
                ]
            }
        }
    },
}