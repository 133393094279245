<template>
    <div>
        <el-form v-if="isSubmit === false" label-width="150px" :model="ruleForm" inline="true" status-icon :rules="rules" ref="ruleForm" class="login-container">
          <h3 class="login_title">Wam Management System</h3>
          <el-form-item label="New Password" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Comfirm Password" prop="checkPassword">
              <el-input type="password" v-model="ruleForm.checkPassword" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="text-align:right">
              <el-button type="primary" @click="submitForm('ruleForm')">Reset Password</el-button>
          </el-form-item>
        </el-form>
      <div class="container" style="width:500px" v-else>{{ message }}</div>
    </div>
   
</template>

<script>
import {resetPassword} from "../../api/auth"
export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please enter new password'));
        } else {
          if (this.ruleForm.checkPassword !== '') {
            this.$refs.ruleForm.validateField('checkPassword');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please enter new comfirm password'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('Please enter same password'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          password: '',
          checkPassword: ''
        },
        rules: {
          password: [
            { validator: validatePass, trigger: 'blur' },
            { min: 4, max: 10, message: 'Password lengths between 4 - 10 digits', trigger: 'blur' }
          ],
          checkPassword: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        },
        isSubmit: false,
        message: ""
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const {id, token} = this.$route.params
            console.log(this.ruleForm)
            resetPassword(id, token, {password: this.ruleForm.checkPassword})
                .then(res=>{
                    console.log(res)
                    const {code} = res.data
                    if(code === 200){
                        this.isSubmit = true
                        this.message = "Your password reset successful! This page will redirect to login page in 5s."
                    }else{
                        this.isSubmit = true
                        this.message = "This link is Expired, Please contact us to get a link. Thank you."
                    }

                    setTimeout(() => {
                      this.$router.push('/')
                    }, 5000);
                })
                .catch(err => console.error(err))
            // alert('submit!');
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      }
    }
}
</script>

<style lang="less" scoped>
.login-container{
    width: 420px;
    border: 1px solid #eaeaea;
    margin: 180px auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
    // box-sizing: border-box;
    .login_title{
        text-align: center;
        margin-bottom: 40px;
        color: #505458;
        font-size: 20px;
    }
    .el-form-item{
        width: 100%;
    }
    .el-input{
        width: 198px;
    }
}
</style>