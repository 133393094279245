<template>
    <div class="container">
        <div class="title">
            Add New User
        </div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px" class="demo-ruleForm">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="First Name" prop="firstName">
                            <el-input v-model="ruleForm.firstName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Last Name" prop="lastName">
                            <el-input v-model="ruleForm.lastName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Email" prop="email">
                            <el-input v-model="ruleForm.email"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Phone" prop="phone">
                            <el-input v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Role" prop="role_id">
                            <el-select v-model="ruleForm.role_id" filterable style="width: 100%">
                                <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Company" prop="company_id">
                            <el-select v-model="ruleForm.company_id" filterable style="width: 100%">
                                <el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Password" prop="password">
                            <el-input v-model="ruleForm.password" type="password"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                    </el-col>
                </el-row>
                <el-form-item class="btn-fields">
                    <el-button type="primary" :loading="isLoading" @click="submitForm('ruleForm')">Submit</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>

    </div>

</template>

<script>
import { getAllCompanies } from "../../../api/companies";
import { getRoles, addNewUser, getUserById } from "../../../api/user";
export default {
    data() {
        return {
            ruleForm: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                role_id: "",
                company_id: "",
                password: ""
            },
            rules: {
                email: [
                    { required: true, message: 'This value is required.', trigger: 'change' }
                ],
                role_id: [
                    { required: true, message: 'Must have at least one value.', trigger: 'change' }
                ],
                company_id: [
                    { required: true, message: 'Must have at least one value.', trigger: 'change' }
                ],
                password: [
                    { required: true, message: 'This value is required.', trigger: 'change' }
                ],
            },
            companies:[],
            roles:[],
            isLoading:false
        };
    },
    mounted(){
        getAllCompanies().then(res => {
            const {code, data} = res.data
            if(code === 200){
                this.companies = data
            }
            console.log(res)
        }).catch(_=>_)

        getRoles().then(res => {
            const {code, data} = res.data
            if(code === 200){
                this.roles = data
            }
            console.log(res)
        }).catch(_=>_)
    },
    methods: {
        submitForm(formName) {
            this.isLoading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    addNewUser(this.ruleForm).then(res => {
                        const {code, message} = res.data
                        if(code === 200){
                            this.$message({message: message, type: 'success'});
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error({message});
                        }
                    }).catch(err=>{
                        // err
                        this.$message.error({message: err.message});
                    })
                    this.isLoading = false
                } else {
                    console.log('error submit!!');
                    this.isLoading = false
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="less" scoped>
.container{
    .title {
        background-color: #eee;
        color: #333;
        padding: 10px;
        font-weight: bold;
    }
    .content{
        padding: 0 3%;
        :deep(.el-form-item){
            margin-bottom:0;
            
        }
        :deep(.el-form--label-top .el-form-item__label){
            padding: 0;
            font-weight: bold
        }
        .btn-fields{
            text-align: right;
            margin-top: 15px;
        }
    }
}
</style>