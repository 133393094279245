import http from "@/utils/request";
import permission from "./mockServiceData/permission";

// export const getMenu = (data) =>{
//     console.log(data)
//     return http.post('/permission/getMenu',data)
// }

export const login = (data) =>{
    return http.post('/api/auth/login',data)
}

export const getMenu = (data) =>{
    return http.post('/api/menu/getMenuList',data)
}

export const resetPassword = (id, token, data)=>{
    return http.post(`/api/auth/resetPassword/${id}/${token}`,data) 
}

export const forgotPassword = (data) =>{
    return http.post(`/api/auth/forgotPassword`,data)
}

export const recaptcha = (data) =>{
    return http.post(`/api/auth/recaptcha`,data)
}