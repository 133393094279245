import {getItemUnits, getItemSelections} from "../api/items"

export default {
  data() {
    var checkNumber = (rule, value, callback) => {
      // console.log(rule)
      const regex = /^[0-9]+(.{1}[0-9]{1,})?$/
      // console.log(value)
      if (regex.test(value.toString().trim())) {
        callback();
      } else {
        callback(new Error("This field requires number only"));
      }
    }
    return {
      activeTabName: 'first',
      statusOptions: [
        {
          id: 0,
          name: "Active",
          value: 1,
          disabled: false
        },
        {
          id: 1,
          name: "Failed",
          value: 0,
          disabled: false
        },
        {
          id: 2,
          name: "Archived",
          value: 2,
          disabled: false
        },
      ],
      retentionOptions: [
        {
          id: 0,
          name: "Crimp",
          value: "Crimp"
        },
        {
          id: 1,
          name: "Wire Whip",
          value: "Wire Whip"
        },
        {
          id: 2,
          name: "Weld",
          value: "Weld"
        },
        {
          id: 3,
          name: "Clamp",
          value: "Clamp"
        },
      ],
      testScheduleOptions: [
        {
          id: 0,
          name: "3 Months",
          value: 3
        },
        {
          id: 1,
          name: "6 Months",
          value: 6
        },
        {
          id: 2,
          name: "12 Months",
          value: 12
        },
        {
          id: 3,
          name: "24 Months",
          value: 24
        },
      ],
      locationOptions: [
        {
          id: 0,
          name: "Avondale",
          value: "Avondale"
        },
        {
          id: 1,
          name: "East Tamaki",
          value: "East Tamaki"
        },
        {
          id: 2,
          name: "Tauranga",
          value: "Tauranga"
        },
        {
          id: 3,
          name: "Hamilton",
          value: "Hamilton"
        },
        {
          id: 4,
          name: "Rotorua",
          value: "Rotorua"
        },
        {
          id: 5,
          name: "New Plymouth",
          value: "New Plymouth"
        },
        {
          id: 6,
          name: "Christchurch",
          value: "Christchurch"
        },
        {
          id: 7,
          name: "Ashburton",
          value: "Ashburton"
        },
        {
          id: 8,
          name: "Timaru",
          value: "Timaru",
        },
      ],
      testPressureUnitOptions: [],
      continuityOptions:[
        {
          id:0,
          name: "End to End",
          value: "End to End"
        }
      ],
      staticLengthUnitOptions:[],
      itemCodeOptions:[],
      endabOptions:[],
      rules: {
        serial: [
          { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        order_number: [
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        ref_number: [
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        test_schedule: [
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        status:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        code:[
          { required: true, message: 'This field is required', trigger: 'change' },
        ],
        test_pressure:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' }
        ],
        test_length:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            // { type: 'number', message: 'This field requires number only', trigger: 'blur' }
            { validator: checkNumber, trigger: 'blur' }
        ],
        final_length:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            // { type: 'number', message: 'This field requires number only', trigger: 'blur' }
            { validator: checkNumber, trigger: 'blur' }
        ],
        continuity:[
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        resistance:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            // { type: 'number', message: 'This field requires number only', trigger: 'blur' }
            { validator: checkNumber, trigger: 'blur' }
        ],
        test_date:[
            // { type: 'date', required: true, message: 'This field is required', trigger: 'blur' }
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        test_schedule:[
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
        authorized_by:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        user_id:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        static_length:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            { type: 'number', message: 'This field requires number only', trigger: 'blur' }
        ],
        nominal_bore:[
            { required: true, message: 'This field is required', trigger: 'blur' },
            { type: 'number', message: 'This field requires number only', trigger: 'blur' }
        ],
        end_a_code:[
          { required: true, message: 'This field is required', trigger: 'change' },
        ],
        end_a_retention:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        end_b_code:[
          { required: true, message: 'This field is required', trigger: 'change' },
        ],
        end_b_retention:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        location:[
            { required: true, message: 'This field is required', trigger: 'change' },
        ],
        time_manufactured:[
            // { type: 'date', required: true, message: 'This field is required', trigger: 'blur' }
            { required: true, message: 'This field is required', trigger: 'blur' },
        ],
      },
    };
  },
  mounted(){
    this.getUnits()
  },
  methods:{
    getUnits() {
      getItemUnits().then( res => {

        const {code, data} = res.data

        if(code === 200) {
          this.testPressureUnitOptions = data.pressureUnits
          this.staticLengthUnitOptions = data.staticLengthUnits
        }
      }).catch(err =>{
        console.warn(err.message)
      })
    },
    elongationDisplayChangeHandler(val){  

        if(val === 0){
            this.removeElongationTestValidate()
        }else{
            this.addElongationTestValidate()
        }

        this.clearValidate()
    },
    continuityDisplayChangeHandler(val){

        if(val === 0){
            this.removeContinuityTestValidate()
        }else{
            this.addContinuityTestValidate()
        }

        this.clearValidate()
    },
    addElongationTestValidate(){
        if(!this.rules.test_length){

            this.$set(this.rules,"test_length", [
                { required: true, message: 'This field is required', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ])
        }

        if(!this.rules.final_length){

            this.$set(this.rules,"final_length", [
                { required: true, message: 'This field is required', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ])
        }
    },
    addContinuityTestValidate(){
        if(!this.rules.resistance){

            this.$set(this.rules,"resistance", [
                { required: true, message: 'This field is required', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ])
        }
    },
    removeElongationTestValidate(){
        this.$delete(this.rules, 'test_length')
        this.$delete(this.rules, 'final_length')
    },
    removeContinuityTestValidate(){
        this.$delete(this.rules, 'resistance')
    },
    clearValidate(){
        this.$nextTick(()=>{
            this.$refs['ruleForm'].clearValidate()
        })
    },
    getItemOptions(){
      getItemSelections().then(res =>{
        // console.log(res,"getItemSelections")
        const {data, code} = res.data

        if(code === 200){
            this.endabOptions = data.endabOptions
            this.itemCodeOptions = data.itemCodeOptions
            // this.filterUser()
        }

      }).catch(err => {
        console.warn(err.message)
        this.$message.error({message: err.message});
      })
    }
  }
};
