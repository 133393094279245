import Mock from "mockjs";
import homeApi from "./mockServiceData/home"
import user from "./mockServiceData/user"
import permission from "./mockServiceData/permission";


// Mock.mock("/api/home/getData", function(){
//     //拦截到请求后的处理逻辑
//     console.log("22")

//     return {

//     }
// })

Mock.mock("/api/home/getData", homeApi.getStatisticalData)

//用户列表
Mock.mock("/api/user/add", user.createUser)
// Mock.mock("/api/user/eedit", user.editUser)
// Mock.mock("/api/user/delete", user.deleteUser)
// Mock.mock("/api/user/getUser", user.getUser)

Mock.mock(/api\/permission\/getMenu/, "post", permission.getMenu)
