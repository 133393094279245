<template>
    <div>
        <img :src="header" style="width: 100%;"/>
        <h5 class="main-title">CERTIFICATE OF HOSE INSPECTION AND TEST</h5>
        <div class="main">
            <div class="field">
                <h6>CUSTOMER</h6>
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Serial Number</label>:<span class="input">{{pdfData.serial}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Test Date</label>:<span class="input">{{pdfData.test_date}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Customer</label>:<span class="input">{{pdfData.name}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Re-Test Due</label>:<span class="input">{{ pdfData.time_retest | moment }}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Address</label>:<span class="input">{{address}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Order Number</label>:<span class="input">{{pdfData.order_number}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Contact</label>:<span class="input">{{pdfData.phone}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Ref N°</label>:<span class="input">{{pdfData.ref_number}}</span></div>
                    </div>
                </div>
            </div>
            <div class="field">
                <h6>ASSEMBLY DETAILS</h6>
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Hose Type</label>:<span class="input">{{pdfData.hosetype}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Code</label>:<span class="input">{{pdfData.code}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Static length mm</label>:<span class="input">{{testPressure}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Nominal Bore mm</label>:<span class="input">{{pdfData.nominal_bore}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>End A Code</label>:<span class="input">{{pdfData.end_a_code}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label class="none">End A Retention</label>:<span class="input">{{pdfData.end_a_retention}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-12 title d-flex flex-row"><label>End A</label>:<span class="input">{{pdfData.end_a}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>End B Code</label>:<span class="input">{{pdfData.end_b_code}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label class="none">End B Retention</label>:<span class="input">{{pdfData.end_b_retention}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-12 title d-flex flex-row"><label>End B</label>:<span class="input">{{pdfData.end_b}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Location</label>:<span class="input">{{pdfData.location}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Date of Manufacture</label>:<span class="input">{{ TimeManufactured }}</span></div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Service</label>:<span class="input"></span></div>
                        <div class="col-6 title d-flex flex-row"><label>Expiry Date</label>:<span class="input">{{ pdfData.time_expired | moment }}</span></div>
                    </div> -->
                </div>
            </div>
            <div class="field">
                <h6>PRESSURE TEST AS 1180.5</h6>
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Test Pressure</label>:<span class="input">{{pressureTest}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Result</label>:<span class="input">{{pressureTestResult | testResult}}</span></div>
                    </div>
                </div>
            </div>
            <div class="field">
                <h6>ELONGATION TEST (Composite Hose Only)</h6>
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Test Length mm</label>:<span class="input">{{lengthTest}}</span></div>
                        <div class="col-6 title d-flex flex-row">
                            <label>Result</label>:<div style="flex:1;text-align: center;"><span style="display:block; height: 20px;" class="input">{{lengthTestResult | testResult}}</span>
                                <!-- <span>No greater than 12% of static length</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"></div>
                        <small class="col-6 text-end">No greater than 12% of static length</small>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Final Length mm</label>:<span class="input">{{finalLengthTest}}</span></div>
                        <div class="col-6 title d-flex flex-row">
                            <label>Result</label>:<div style="flex:1;text-align: center;"><span style="display:block; height: 20px;" class="input">{{finalLengthTestResult | testResult}}</span></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"></div>
                        <small class="col-6 text-end">Between +5% and –2% of static length</small>
                    </div>
                </div>
            </div>
            <div class="field">
                <h6>CONTINUITY AND ELECTRICAL RESISTANCE TEST&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AS 1180.13C</h6>
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Continuity</label>:<span class="input">{{continuityTest}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>Result</label>:<span class="input">{{continuityTestResult | testResult}}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>Resistance</label>:<span class="input">{{resistanceTest}}</span>ohms</div>
                        <div class="col-6 title d-flex flex-row"><label>Result</label>:<span class="input">{{resistanceTestResult | testResult}}</span></div>
                    </div>
                </div>
            </div>
            <div class="field">
                <h6>COMMENTS:</h6>
                <div class="content comment">
                    {{pdfData.description}}
                </div>
            </div>
            <div class="field" style="position: relative">
                <div class="content">
                    <div class="row">
                        <div class="col-6 title d-flex flex-row"><label>AUTHORIZATION BY</label>:<span class="input">{{pdfData.authorized_person}}</span></div>
                        <div class="col-6 title d-flex flex-row"><label>SIGNATURE</label>:<span class="input">
                            <img style="position: absolute; height: 50px; right: 50px; bottom: 5px" :src="pdfData.signature" />
                        </span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-title">
            <a>www.hoseshop.co.nz</a>
        </div>
    </div>
</template>

<script>
import {getPdfById} from "../../api/pdf"
import moment from "moment";
export default {
    data(){
        return{
            header: require("../../assets/pdf_header.png"),
            pdfData:{},
            isTimeManufacturedDisplay: true
        }
    },
    created(){
        getPdfById(this.$route.params.id).then(res =>{
            const {code,data}= res.data
            if(code === 200){
                if(data.time_manufactured === "1900-01-01 00:00:00") this.isTimeManufacturedDisplay = false
                this.pdfData = data
            }
        }).catch(err=>console.error(err.message))
    },
    filters: {
        moment: function (date) {
            if(date && moment(date).format('YYYY-MM-DD') !== "Invalid date") return moment(date).format('YYYY-MM-DD');
            
            return ''
        },
        testResult: function(value){
            if(!value){
                if(value === 0){
                    return "Fail"
                }

                return ""
            }

            return "Pass"
        }
    },
    computed:{
        TimeManufactured(){
            return this.isTimeManufacturedDisplay? moment(this.pdfData.time_manufactured).format('YYYY-MM-DD') : 'Not Available'
        },
        address(){

            if(this.pdfData.address_line1) return `${this.pdfData.address_line1}, ${this.pdfData.suburb}`
            return this.pdfData.address
        },
        pressureTest(){
            return this.pdfData.test_pressure_display === 1? `${this.pdfData.test_pressure} ${this.pdfData.test_pressure_unit}` : ""
        },
        lengthTest(){
            return this.pdfData.test_elongation_display === 1? this.pdfData.test_length : ""
        },
        finalLengthTest(){
            return this.pdfData.test_elongation_display === 1? this.pdfData.final_length : ""
        },
        continuityTest(){
            return this.pdfData.test_continuity_display === 1? this.pdfData.continuity : ""
        },
        resistanceTest(){
            return this.pdfData.test_continuity_display === 1? this.pdfData.resistance : ""
        },
        pressureTestResult(){
            return this.pdfData.test_pressure_display === 1? this.pdfData.test_pressure_result : ""
        },
        lengthTestResult(){
            return this.pdfData.test_elongation_display === 1? this.pdfData.test_length_result : ""
        },
        finalLengthTestResult(){
            return this.pdfData.test_elongation_display === 1? this.pdfData.final_length_result : ""
        },
        continuityTestResult(){
            return this.pdfData.test_continuity_display === 1? this.pdfData.continuity_result : ""
        },
        resistanceTestResult(){
            return this.pdfData.test_continuity_display === 1? this.pdfData.resistance_result : ""
        },
        testPressure(){
            return `${this.pdfData.static_length} ${this.pdfData.static_length_unit}`
        }
    }
}
</script>

<style lang="less" scoped>
.main-title {
    text-align: center;
    font-weight: bold;
}

.main {
    border: 1px solid black;
    padding: 10px;
    font-size: 13px;

    .field {
        margin-bottom: 5px;

        h6{
            text-decoration: underline;
        }

        label.none {
            min-width: 30px;
        }

        label {
            min-width: 80px;
        }
        .title {
            /* font-weight: bold; */
            /* font-size: 1.1rem */
            margin-top: 7px;
        }

        .input {
            flex: 1;
            border-bottom: 1px dotted black;
            margin-left: 10px;
            // margin-bottom: 10px;
            font-weight: bold;
            text-align: center;
        }

        .comment {
            min-height: 40px;
            text-decoration: underline;
            text-decoration-style: dotted;
            font-size: 12px;
            font-weight: bold;
            text-underline-offset: 7px;
        }
    }
}

</style>