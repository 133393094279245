//筛选路由
/**
 * 定义筛选路由方法
 * @param {*} menuList 前端路由
 * @param {*} dyMenuList 后端路由
 */
import {cloneDeep} from "lodash"
export function menuRules(menuList, dyMenuList){
    // console.log("+++++menuList+++++",menuList)
    // console.log("+++++dyMenuList+++++",dyMenuList)
    //最终筛选符合后端条件的路由
    let menu = []
    //深拷贝前端路由
    // const arr = [...menuList] 浅拷贝
    // const arr = JSON.parse(JSON.stringify(menuList)) 深拷贝

    // const arr = _.cloneDeep(menuList) //全局引用
    const arr = cloneDeep(menuList)

    arr.forEach(one => {
        dyMenuList.forEach(two =>{
            if(one.name === two.name){
                // 判断是否包含children=[]
                if(two.children && two.children.length > 0){
                    one.children = menuRules(one.children, two.children)
                }
                //添加数组
                menu.push(one)
            }
        })
    });

    // console.log("这个menu", menu)

    return menu
}