<template>
    <div>one</div>
</template>

<script>
export default{
    data(){
        return{

        }
    }
}
</script>